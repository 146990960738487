<template>
	<div>
		<div class="header ellipsis_one" @click="goback"><img src="../assets/images/back.png" alt=""><span> {{ nameTitle }}</span></div>
		<div class="blian22"></div>
		<div class="container">
			<div class="downcont-head">
				<h4 style="font-size: 0.8rem;text-align: center;padding: 0.5rem 0;">{{ nameTitle  }}</h4>
				<!--<img src="../assets/images/activity.png" alt="">-->
				<button @click="uploadInfo" v-if="flag">在线预览</button>
				<button @click="lookNow" v-else>在线预览</button>
			</div>
			<div class="downcont-main">
				<h3>下载至手机</h3>
				<p>如果您是苹果手机请选择下载电脑操作</p>
				<button @click="uploadInfo">点击下载</button>
			</div>
		</div>
		<div class="container mt10">
			<div class="downcont-main">
				<h3>下载至电脑</h3>
				<div class="dcm-img">
					<img src="../assets/images/phone.png" alt="" class="imgleft">
					<img src="../assets/images/line.png" alt="" class="imgcent">
					<img src="../assets/images/computer.png" alt="" class="imgright">
				</div>
				<p>您可以在浏览器中打开以下网址，查看该资源</p>
				<div class="dcm-http flex_outside">
					<span class="http ellipsis_one flex_inside">{{ testPaperPath }}</span>
					<span class="copy " @click="doCopy">复制</span>
				</div>
			</div>
		</div>
		<div class="instructions">
			<h3>说明：</h3>
			<p>1.微信中如果点击【点击下载】按钮没有反应，请点击右上角菜单，使用手机浏览器打开。</p>
			<p>2.苹果手机由于系统限制无法下载到手机上，请按照【下载到电脑】的操作进行下载。</p>
		</div>
	</div>
</template>
<script>
	import { Toast } from 'mint-ui';
	export default {
		name: "downcont",
		data() {
			return {
				testPaperPath: "",
				nameTitle: "",
				isPdf: '',
				flag:false
			};
		},
		methods: {
			//在线预览
			lookNow() {
				let path = '';
				if(!this.isPdf) {
					path = './unitsummaryWorld'
				} else {
					path = './unitsummaryPdf'
				}
				this.$router.push({
					path: path,
					query: {
						path: this.testPaperPath,
						titleName: this.$route.query.testName
					}
				});
			},
			//复制
			doCopy() {
				let that = this;
				this.$copyText(this.testPaperPath).then(function() {
					Toast({
						message: '复制成功',
						position: 'middle',
						duration: 1000
					});
				}, function() {
					Toast({
						message: '复制失败',
						position: 'middle',
						duration: 1000
					});
				})
			},
			//下载
			uploadInfo() {
				var aEle = document.createElement("a"); // 创建a标签
				aEle.href = this.testPaperPath; // content为后台返回的下载地址
				aEle.click()
			},
			goback: function() {
				this.$router.go(-1)
			},
			appSource() {
				const u = navigator.userAgent;
				const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				if(isiOS) {
					this.flag = true; // 若是ios 做操作
				} else {
					this.flag = false; // 要不就是Android
				}
				console.log(this.flag)
			}
		},
		mounted() {
			this.nameTitle = this.$route.query.testName;
			this.testPaperPath = this.$route.query.testPaperPath;
			console.log(this.$route.query.testPaperPath.split('.'))
			let urlArr = this.$route.query.testPaperPath.split('.'); //切割地址判断是pdf还是world
			if(urlArr[urlArr.length - 1] != 'pdf') {
				//不是pdf文件
				this.isPdf = 0;
			} else {
				this.isPdf = 1;
			}
		},
		created(){
			this.appSource()
		}
	};
</script>